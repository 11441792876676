import {ref, computed, getCurrentInstance, reactive, toRefs, onBeforeMount,onMounted, defineComponent, provide,nextTick} from 'vue';
import GenCodeUtil,{IGenCodeDataObj} from "@/views/genCode/genCodeUtils";
import config from "@/utils/config";
export default defineComponent({
    name: 'GenCodeCard',
    title: "代码生成模块",
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IGenCodeDataObj=reactive<IGenCodeDataObj>({
            utilInst:{} as any,
            formRef:null,
            refMap:new Map(),
            applications:config.applications,
            mainTbFields: [],//主表的字段
            mainTbTreeData: [],//主表下拉树
            dialogVisible: false,//穿梭狂弹出框组件的显示/影藏
            chooseTypeVisible:false,//代码生成种类选择弹出框的显示/影藏
            titles: ["未选字段", "已选字段"],//穿梭框左右的标题
            transformLeftData: [],//穿梭框左边的数据（每项数据：{key:'',value''}）
            transformRightData: [],//穿梭框右边的数据：已选项。（每项数据：只包含key）
            billTypeTreeData:[],//单据类型数据源
            curOperGrid: "",//当前正在操作的明细表格，供穿梭框点击确认的时候用，方便把已选择的穿梭框数据回填到正在操作的表格
            dynamicDetailGridArr: [],//卡片明细表格数组,卡片有多少个明细表格
            compParams: {//代码生成模块组件传给dialog的初始化参数
                modelPath: utils.Api.buildUrl('/genCode'),
                hasDetails: true,//有明细表格
                details:['childTable','queryFields','listFields','cardFields']
            },
            //卡片显示字段明细表格中是否必填列是一个常量下拉框，该下拉框取值来自于此
            yesNoSel: [{ value: 0, label: "否" },{ value: 1, label: "是" }],
            //字段的类型
            cardFieldType: [],
            //明细可编辑表格可用字段类型
            fieldType: [],
            //表格字段的对其方式
            alignType: [
                { value: "left", label: "left" },
                { value: "center", label: "center" },
                { value: "right", label: "right" }
            ],
            //代码生成模块表单属性
            form: {} as any,
            //选择要生成文件种类form表单(虽然说list.vue、card.vue都依赖helper，这里还让选择是否生成helper的原因：如果你生成代码之后在helper中写好了很多代码，这个时候只想生成vue文件就可以不选择helper了)
            genTypeForm:{
                controller:true,helper:true,bean:true,detailBean:true,buffer:true,
                dao:true,detailDao:true,card:true,cardHelper:true,list:true,listHelper:true,
                showDetail:true,report:false,bmap:false
            },
            //表单验证规则
            rules: utils.UtilPub.commonFormValidRule([{application:'请选择归属应用'},{tbName:'请选择表名'},{modelName:'请输入模块名称'},{javaPath:'请输入后台代码存放路径'},{vuePath:'请输入前代码存放路径'}])
        })
        onBeforeMount(()=>{dataObj.utilInst=new GenCodeUtil(proxy,dataObj)})
        onMounted(()=>{
            nextTick(()=>{
                dataObj.cardFieldType=dataObj.utilInst.getFieldTypeArr(true);
                dataObj.fieldType=dataObj.utilInst.getFieldTypeArr(false);
            })
        })
        //---------------------------computed------------
        //字段下拉框（根据表名显示字段）
        const fieldComboParam=computed(()=>{
            return (tbName:string) => {
                return {
                    comboId:"fieldCombo",
                    txtField: "F_CODE",
                    modelMethod: utils.Api.buildUrl("/genCode/combo"),
                    comboType: "fieldCombo",
                    tbName: tbName
                }
            };
        })
        //构建明细表格参数（除了第一个明细表格childGrid）
        const detailGridParams=computed(()=>{
            return (options: { detailType:string,clickEvent?:string }) => {
                if(options.detailType=='childTable'){//第一个表格（关联子表）的明细参数
                    return {detailParam: {canPage: false,queryParam: {detailType:'childTable'},modelMethod: utils.Api.buildUrl("/genCode/detail")}}
                }else{//除了第一个表格（关联子表）之外的明细表格参数，其实就多了一个toolbar头部的点击事件，第一个表格采用EditGrid默认的新增事件，其它明细表格自己指定了新增处理事件
                    return {detailParam: dataObj.utilInst.buildExChildGridDetailParam({clickEvent:options.clickEvent,detailType:options.detailType,utils:utils})}
                }
            }
        })
        //打开弹出框的回调事件
        const beforeOpen=async(data:any,addOrLoad:string)=>{
            dataObj.billTypeTreeData =JSON.parse(data.billTypeData);
            //初始化主表下拉树的值
            const res = await utils.Api.tableTreeData({});
            dataObj.mainTbTreeData = res.data;
        }
        //由于动态构造的明细表格没有纳入到传入cardEngine的compParams中管理，因此动态明细表格的加载需要我们手动完成，
        //然而有哪些动态表格呢，这个又是由第一个明细表格---关联子表创建出来的，所以我们需要等第一个明细表格加载完成之后，
        //才能来构建和加载动态明细表格，然而明细表格的加载完成是在beforeOpen之后的，所以我们在afterOpened里面完成动态明细表格的操作
        const afterOpened=async (data:any, addOrLoad:string,engineInst:any)=>{
            dataObj.utilInst.reBuildDynamicGrid(); //根据最新的子表集合重新构建明细表格动态部分（业务模块的明细表格集合）
            if("/load"==addOrLoad){//如果是编辑，需要把已经选择好的主表字段给存下来,在弹出穿梭框的时候要用到，免得去后台查询，还需要查询动态明细表格的数据
                await nextTick(async()=>{
                    //注意：1、不能用forEach和await结合用；
                    //     2、dataObj.dynamicDetailGridArr将会被页面用于创建表格，由于下方要用到页面的表格加载数据，为了预防页面还没有把表格创建出来就被下方代码使用，
                    //        需要把下方代码放到nextTick中执行
                    for (let i = 0; i < dataObj.dynamicDetailGridArr.length; i++) {
                       nextTick(async ()=>{//这里不加居然查询不出来，tmd
                           let grid = dataObj.dynamicDetailGridArr[i]["tbName"];
                           await dataObj.refMap.get(grid).reloadGrid({mainId:dataObj.form.id});
                       })
                    }
                })
                let fields = dataObj.refMap.get('table').getCheckedNodes()[0].data.fields;
                dataObj.mainTbFields.length = 0;//清空主表字段,否则点击重置或者重新加载卡片的时候，会造成字段不断增加
                for (let fieldName in fields) {
                    dataObj.mainTbFields.push({ fieldName: fieldName, fieldCaption: fields[fieldName] });
                }
            }
        }
        //主表变了，清空所有的明细
        const clearDetail=()=>{
            dataObj.refMap.get("childTable").clearDetailData();
            dataObj.refMap.get("queryFields").clearDetailData();
            dataObj.refMap.get("listFields").clearDetailData();
            dataObj.refMap.get("cardFields").clearDetailData();
            //这里可以考虑从refMap中删除dynamicDetailGridArr中的项
            dataObj.dynamicDetailGridArr.length = 0;//卡片明细数组清空
            dataObj.transformRightData.length = 0;//穿梭框已选清空(万一穿梭框右边部分有值，再选一张主表，弹出穿梭框的时候，数据有可能会乱)
        }
        //主表变化事件
        const change=(val:string)=>{
            clearDetail();
            let checkedNodes=dataObj.refMap.get('table').getCheckedNodes();
            if(checkedNodes.length>0){
                //根据选择的主表，把模块名称、模块描述给填上
                let caption = checkedNodes[0].data.caption;
                dataObj.form.modelCaption = caption;
                if(dataObj.form.billFlag==0)
                dataObj.form.modelName = dataObj.utilInst.getJavaNameByDbName(val);
                //后台返回给前台的主表数据中包含了该主表的所有字段信息，但是字段信息是以对象的形式返回的，
                //这里我们解析为一个数组形式，放到data中，在弹出穿梭框的时候要用到，免得去后台查询
                dataObj.mainTbFields.length = 0;//清空主表字段
                let fields = checkedNodes[0].data.fields;
                for (let fieldName in fields) {
                    dataObj.mainTbFields.push({ fieldName: fieldName, fieldCaption: fields[fieldName] });
                }
            }
        }
        //是否单据下拉select发生改变事件
        const billFlagChange=(val:number)=>{//如果不是单据，则清空单据模块cascader已经选择项
            if(val==0)dataObj.form.billModel='';
        }
        //单据类型改变事件
        const billTypeChange=(val:string)=>{
            dataObj.form.modelName =val;
        }
        //关联子表--子表名称cascader组件选择变化事件
        //1、选择好子表之后，更新tab的个数，因为页面明细表格由两部份构成，
        //   固定部分：列表查询字段、列表显示字段、卡片显示字段；动态部分：根据关联子表（第一个明细表格）来显示，有多少个子表就会有多少个明细表格在固定部分后面
        //2、根据选择的表名动态设置该行主表关联字段combogrid的内容，combogrid的内容其实就是所选表的字段集合
        //3、检查关联子表明细表格中是否存在子表重复的情况
        //4、为该行添加新的属性并且赋值
        const tbDetailChange=(row:any)=>{
            dataObj.utilInst.reBuildDynamicGrid();  //根据最新的子表集合重新构建明细表格动态部分（业务模块的明细表格集合）
            if (null == row.F_TABLE) return;
            //重新根据新选择的子表查询该子表的字段集合（更新主表关联字段combogrid的内容）
            let tbName = row.F_TABLE;
            dataObj.refMap.get('fieldCombo').setQueryParams({ tbName: tbName });
            let childTableGridData = dataObj.refMap.get('childTable').getDetailData();
            if (childTableGridData.length != 0 && dataObj.utilInst.childTableIsDouble())//提示有重复，如果要再次新增行会报错
                utils.Tools.info({ message: "子表存在重复" });
            row.F_MODEL_NAME=dataObj.utilInst.getJavaNameByDbName(tbName);//设置该行的模块名称，在生成代码的时候，会以该模块名称打头
            dataObj.refMap.get('childTable').setRowData(row, row.rowIndex);
        }

        //关联子表表格头部的新增按钮事件中的新增之前方法回调，如果存在重复的子表名称将不会允许继续新增
        const beforeAdd=()=>{
            if (!dataObj.form.tbName) {
                utils.Tools.info({ message: "请先选择主表" });
                return false;
            } else {
                //检查是否存在子表重复添加的情况
                if (!dataObj.utilInst.childTableIsDouble()) {
                    return true;
                } else {
                    utils.Tools.info({ message: "子表存在重复" });
                }
            }
        }

        //各个明细表格中固定列的删除后事件
        const afterDel=(options:any)=>{
            dataObj.utilInst.reBuildDynamicGrid(); //根据最新的子表集合重新构建明细表格动态部分（业务模块的明细表格集合）
        }

        //-----------------------明细表格头部新增按钮事件------------------------------
        //列表查询字段、列表显示字段、卡片显示字段表格头部的新增按钮，会弹出穿梭框，让选择要显示哪里主表字段
        const grid234AddHandler=async (detailType:string)=>{
            dataObj.curOperGrid = detailType;//当前操作的是代码生成模块的哪个明细表格
            dataObj.utilInst.genTransformContent(dataObj.mainTbFields,detailType);
        }

        //由子表生成的卡片明细表格头部的新增按钮事件，添加卡片明细表格要显示的字段
        const dynamicDetailGridAddHandler=async (detailType:string)=>{
            dataObj.curOperGrid = detailType;//当前操作的是代码生成模块的哪个明细表格
            dataObj.utilInst.genTransformContent(dataObj.utilInst.getFieldInfoByDetailGridName(detailType),detailType);
        }
        //-----------------------卡片明细表格头部新增按钮事件-----------------------------

        //单击穿梭框的确认按钮，把选择的信息回填到相应的明细表格中,先清空已有的，再把选择的添加上去
        const chooseFieldsSureHandler=async()=>{
            await dataObj.utilInst.doTransTransformSure();
        }
        //把各个明细表格的数据赋值到form表单中的对应属性身上
        const setDetailsJson=async()=>{
            await dataObj.utilInst.doSetDetailsJson();
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //如果是单据，必须旋转单据模块
            if(dataObj.form.billFlag==1 && !dataObj.form.billModel){
                utils.Tools.success({message: "请选择单据模块!"});
                return false;
            }
            return true;//如果只想根据主表生成bean、dao等，就不需要任何明细表格的数据了，那么就不需要上面的验证
        }
        //点击生成代码按钮
        const genCodeHandler=()=>{
            let tbChild=dataObj.refMap.get('childTable').getDetailData();
            if(tbChild.length==0){//说明卡片不需要显示明细
                dataObj.genTypeForm.detailBean=false;
                dataObj.genTypeForm.detailDao=false;
                dataObj.genTypeForm.showDetail=false;
            }
            dataObj.chooseTypeVisible=true;
        }
        //生成代码选择框确认事件
        const chooseTypeSureHandler=async()=>{
            let params=Object.assign({id: dataObj.form.id},dataObj.genTypeForm); //把要生成代码的种类和使用那条代码生成规则传入后台
            const res = await utils.Api.genCode({params:params});
            utils.Tools.success({ message: res.msg });
            dataObj.chooseTypeVisible=false;
        }
        return{
            ...toRefs(dataObj),fieldComboParam,detailGridParams,beforeOpen,afterOpened,clearDetail,
            change,billFlagChange,billTypeChange,tbDetailChange,beforeAdd,afterDel,grid234AddHandler,dynamicDetailGridAddHandler,
            chooseFieldsSureHandler,setDetailsJson,beforeSaveHandler,genCodeHandler,chooseTypeSureHandler
        }
    }
});